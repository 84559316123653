import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "./download-on-app-store.0455b119.png"

function App() {
  return (
    <Container fluid className="App">
      <Row>
        <Col>
          <header className="App-header">
            <h1 >Weather Info Today</h1>
            <a href="https://apps.apple.com/us/app/id1459440260">
              <Image src={logo} className="img-fluid" alt="Download on the App Store" />
            </a>
          </header>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
